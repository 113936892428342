

<template>
    <ArrowCorner2 v-if="type === 'corner2'" :color="color" :secondaryColor="secondaryColor" :opacity="opacity"></ArrowCorner2>
    <ArrowCorner3 v-else-if="type === 'corner3'" :color="color" :secondaryColor="secondaryColor" :opacity="opacity"></ArrowCorner3>
    <ArrowLine1 v-else-if="type === 'line1'" :color="color"></ArrowLine1>
    <ArrowLine2 v-else-if="type === 'line2'" :color="color" :secondaryColor="secondaryColor" :opacity="opacity"></ArrowLine2>
    <ArrowLine3 v-else-if="type === 'line3'" :color="color" :secondaryColor="secondaryColor" :opacity="opacity"></ArrowLine3>
    <ArrowBold1 v-else-if="type === 'bold1'" :color="color"></ArrowBold1>
    <ArrowBold2 v-else-if="type === 'bold2'" :color="color" :secondaryColor="secondaryColor" :opacity="opacity"></ArrowBold2>
    <ArrowBold3 v-else-if="type === 'bold3'" :color="color" :secondaryColor="secondaryColor" :opacity="opacity"></ArrowBold3>
    <ArrowCorner1 v-else :color="color"></ArrowCorner1>
</template>

<script>
export default {
  name: 'Arrow',
  components: { 
    ArrowCorner1: () => import("@/components/arrows/corner1.vue"),
    ArrowCorner2: () => import("@/components/arrows/corner2.vue"),
    ArrowCorner3: () => import("@/components/arrows/corner3.vue"),
    ArrowLine1: () => import("@/components/arrows/line1.vue"),
    ArrowLine2: () => import("@/components/arrows/line2.vue"),
    ArrowLine3: () => import("@/components/arrows/line3.vue"),
    ArrowBold1: () => import("@/components/arrows/bold1.vue"),
    ArrowBold2: () => import("@/components/arrows/bold2.vue"),
    ArrowBold3: () => import("@/components/arrows/bold3.vue"),
},
  props: {
    arrowSettings: {
      type: Object,
    },
  },
  computed: {
    type() {
      return this.arrowSettings?.iconType;
    },
    color() {
      return this.arrowSettings?.color || "black";
    },
    secondaryColor() {
      return this.arrowSettings?.secondaryColor || "#A3A3A3";
    },
    opacity() {
      return this.arrowSettings?.opacity || 1;
    }
  }
}
</script>

<style lang="css" scoped>
</style>